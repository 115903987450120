import axios from 'axios'

import { ref } from 'vue'
import { store } from '../store/index'
import { refreshToken } from '@/utils/useToken'

const api = axios.create({
	baseURL: window.location.origin + '/api'
})

api.defaults.headers.common.Authorization = window.localStorage.getItem('access_token')

export const useApi = (endpoint) => {
	const data = ref()
	const error = ref(null)

	const get = async (query, config) => {
		store.commit('setLoading', true)
		try {
			const res = await api.get(`${endpoint}${typeof query !== 'undefined' ? query : ''}`, config)
			data.value = res.data
		} catch (e) {
			console.log('error', e.request)
			if ((e.request && e.request.readyState === 4 && e.request.status === 0) || e.request.status === 401) {
				await refreshToken()
				document.location.reload()
			} else error.value = e.response?.data
		} finally {
			store.commit('setLoading', false)
		}
	}

	const post = async (payload = {}) => {
		store.commit('setLoading', true)

		try {
			const res = await api.post(endpoint, payload)
			data.value = res.data
		} catch (e) {
			if (e.request && e.request.readyState === 4 && e.request.status === 0) {
				await refreshToken()
				document.location.reload()
			} else error.value = e.response?.data
		} finally {
			store.commit('setLoading', false)
		}
	}

	const del = async (query, data) => {
		await store.commit('setLoading', true)

		const config = data ? { data } : undefined

		try {
			const res = await api.delete(`${endpoint}${query || ''}`, config)
			data.value = res.data
		} catch (e) {
			if (e.request && e.request.readyState === 4 && e.request.status === 0) {
				await refreshToken()
				document.location.reload()
			} else error.value = e.response?.data
		} finally {
			store.commit('setLoading', false)
		}
	}

	return {
		data,
		error,
		get,
		post,
		del
	}
}
