<template lang="pug">
button.button(:class="[view, {disabled}]")
	slot

</template>

<script>
export default {
	name: 'BaseButton',
	props: {
		view: {
			type: String,
			default: 'default'
		},
		disabled: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
.button {
	padding: 0 16px;
	font-size: 16px;
	line-height: 40px;
	height: fit-content;
	color: $colorInverse;
	border: none;
	border-radius: $radiusPrimary;
	cursor: pointer;
	transition-property: background-color, box-shadow, opacity;
	transition-duration: 0.3s;

	&:hover {
		box-shadow: $shadowPrimary;
	}

	&.default {
		background-color: $bgMain;

		&:hover {
			background-color: $mainColorHover;
		}
	}

	&.danger {
		background-color: $error;

		&:hover {
			background-color: rgba($error, 0.8);
		}
	}

}
</style>
