<template lang="pug">
TheHeader(v-if="$route.name !== 'Login' && isAdmin")
.container
	TheBreadcrumb
	router-view
TheBar(v-if="$route.name !== 'Login' && isAdmin")
transition(name="fade")
	TheLoader(v-show="loading")
TheAlert(v-model:show="alert.show" :type="alert.type") {{ alert.title }}
</template>

<script>
import { computed, onUpdated, ref } from 'vue'
import { store } from './store'

import TheHeader from '@/components/TheHeader'
import TheBar from './components/TheBar'
import TheAlert from './components/TheAlert'
import TheLoader from './components/TheLoader'
import TheBreadcrumb from './components/TheBreadcrumb'

export default {
	name: 'TheLayout',
	components: {
		TheHeader,
		TheBreadcrumb,
		TheLoader,
		TheBar,
		TheAlert
	},
	setup () {
		const loading = computed(() => store.state.loading)
		const alert = computed(() => store.state.alert)
		const checkAdmin = () => {
			return localStorage.account && JSON.parse(localStorage.account).isAdmin
		}
		const isAdmin = ref(checkAdmin())

		onUpdated(async () => {
			isAdmin.value = checkAdmin()
		})

		return {
			loading,
			alert,
			isAdmin
		}
	}
}
</script>

<style lang="scss">
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: inherit;
	color: inherit;
	text-decoration: none;
}

body {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 20px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $colorPrimary;
}

</style>
