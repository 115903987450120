export const roles = {
	// ADMIN: 'Администратор',
	OPERATOR: 'Оператор',
	PTS: 'ПТС',
	FACTORY: 'Завод',
	FACTORY_NRS: 'Завод НРС',
	FISHERMAN: 'Добытчик',
	BOX_CONTROLLER: 'Контроллер'
}

export const rolesAuth = {
	ADMIN: null,
	OPERATOR: null,
	PTS: null,
	FACTORY: null
}
